import React from 'react';
import './App.css';
import {Routes, Route, BrowserRouter} from 'react-router-dom';
import Footer from './components/Footer/Footer';
import 'bootstrap';
import 'bootstrap/dist/css/bootstrap.min.css';
import NewHome from './NewHome/NewHome';


function App() {
    return (
        <BrowserRouter>
            <Routes>
                <Route path="/" exact element={<NewHome/>}/>
            </Routes>
            <Footer/>
        </BrowserRouter>
    );
}

export default App;
