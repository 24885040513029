import React from 'react';

import "./NewHome.css";

const NewHome = () => {
    const handleDownload = () => {
        const pdfUrl = './2025_KKP.pdf'; // Path to your PDF file in the public folder
        const link = document.createElement('a');
        link.href = pdfUrl;
        link.download = '2025_KKP.pdf'; // The filename for the downloaded file
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      };

    return (
        <div className="NewHome-container" >
            <div className="section">
                <h1 className="header-title">Kornel Kovats</h1>
                <p className="header-subtitle">Software Engineer</p>
                <div id="stars"></div>
                <div id="stars2"></div>
                <div id="stars3"></div>
                <div id="stars4"></div>
            </div>
            <div className="break">

            </div>

            <section id="about" className="section about">
                <h2>About Me</h2>
                <p>
                    Hi, I'm Kornel, a passionate software engineer specializing in cloud technologies and innovative solutions. With a background in contaminated land investigations and a knack for problem-solving.
                </p>
            </section>

            {/* Projects Section
            <section id="projects" className="section projects">
                <h2>My Projects</h2>
                <div className="project-grid">
                    <div className="project-card">
                        <h3>Project 1</h3>
                        <p>A description of the first project. It's innovative and impactful.</p>
                    </div>
                    <div className="project-card">
                        <h3>Project 2</h3>
                        <p>A description of the second project. It showcases my expertise in technology.</p>
                    </div>
                </div>
            </section> */}

            <section id="technologies" className="section technologies">
                <h2 className='tech-header'>Technologies I Work With</h2>
                <ul className="tech-list">
                    <li>Node.js</li>
                    <li>React</li>
                    <li>GCP</li>
                    <li>Docker</li>
                    <li>Kubernetes</li>
                </ul>
            </section>

            <section id="contact" className="section contact">
                <h2>Contact Me</h2>
                <form className="contact-form">
                    <input type="text" placeholder="Your Name" className="input" />
                    <input type="email" placeholder="Your Email" className="input" />
                    <textarea placeholder="Your Message" className="textarea"></textarea>
                    <button type="submit" className="button">Send Message</button>
                </form>
            </section>
            <section className="cv-form">
                <h2>Download CV</h2>
                <div className="cv-button">
                    <button onClick={handleDownload} className="button">Download</button>
                </div>
            </section>
        </div>
    );
};

export default NewHome;
